<template>
  <div class="BaseToggle">
    <label
      v-if="label"
      class="BaseToggle__label"
      data-cy="base-toggle-label"
    >
      {{ label }}
    </label>

    <div
      @keypress.space="setNextToggleActiveAndEmit"
      aria-disabled="disabled"
      :class="['BaseToggle__toggles row row--width-auto', {
        'BaseToggle__toggles--disabled': disabled,
      }]"
      data-cy="base-toggle-toggle-wrapper"
      :tabindex="disabled ? '-1' : '0'"
    >
      <span
        v-for="(toggle, index) in dataToggles"
        v-html="toggle.toggle"
        @click="setActiveAndEmit(index)"
        :key="index"
        :class="[
          'BaseToggle__toggle', {
            'BaseToggle__toggle--active': toggle.active,
          }
        ]"
        data-cy="base-toggle-option"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseToggle',

  props: {
    activeOption: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    toggles: { // Array of Strings
      type: Array,
      required: true,
    }
  },

  created () {
    this.configureToggles()
  },

  data () {
    return {
      dataToggles: null,
    }
  },

  watch: {
    activeOption () {
      // In case toggles is changed but activeOption isn't passed
      if (!this.activeOption) return
      // Set the active toggle via activeOption prop
      const matchingToggle = this.dataToggles
        .filter(toggle => toggle.toggle.toLowerCase() === this.activeOption.toLowerCase())[0]
      if (matchingToggle) {
        // Set all active to false
        this.dataToggles.forEach(toggle => {
          this.$set(toggle, 'active', false)
        })
        this.$set(matchingToggle, 'active', true)
      }
    },

    toggles () {
      this.configureToggles()
    },
  },

  methods: {
    configureToggles () {
      this.dataToggles = this.toggles.map((toggle, index) => {
        // If an active option is passed, that option should be active
        if (this.activeOption) {
          if (toggle.toLowerCase() === this.activeOption.toLowerCase()) {
            return { toggle, active: true }
          }
        }
        // If an active option isn't passed, the first option should be active
        else if (index === 0) return { toggle, active: true }
        return { toggle, active: false }
      })
    },

    setActiveAndEmit (index) {
      if (this.disabled) return
      this.dataToggles.forEach(toggle => {
        this.$set(toggle, 'active', false)
      })
      this.$set(this.dataToggles[index], 'active', true)
      this.$emit('toggle', this.dataToggles[index].toggle.toLowerCase())
    },

    setNextToggleActiveAndEmit (event) {
      event.preventDefault()
      if (this.disabled) return
      const oldactiveOption = this.dataToggles.filter(toggle => toggle.active === true)[0]
      const oldactiveOptionIndex = this.dataToggles.indexOf(oldactiveOption)
      const newactiveOption = this.dataToggles.length - 1 === oldactiveOptionIndex
        ? this.dataToggles[0]
        : this.dataToggles[oldactiveOptionIndex + 1]
      this.$set(oldactiveOption, 'active', false)
      this.$set(newactiveOption, 'active', true)
      this.$emit('toggle', newactiveOption.toggle.toLowerCase())
    },
  }
}
</script>

<style lang="sass">
.BaseToggle
  +flex($direction: column)
  $block: &

  &__label
    +font-medium
    color: #9EA0A5
    font-size: rem(12px)
    line-height: rem(18px)
    margin-bottom: rem(7px)

  &__toggle
    +flex($align: center, $justify: center, $grow: 1)
    background-color: #EEEFF3
    border: rem(1px) solid #EEEFF3
    color: lighten(#3E3F42, 40)
    font-size: rem(14px)
    line-height: rem(22px)
    max-height: rem(36px)
    padding: rem(7px) rem(22px)
    text-transform: capitalize
    transition: color 0.15s ease-in
    user-select: none
    width: inherit // Allows BaseToggle to be full-width on mobile

    &:first-child
      border-radius: rem(4px) 0 0 rem(4px)

    &:last-child
      border-radius: 0 rem(4px) rem(4px) 0

    &--active
      background-color: $white
      border-color: #D8DCE6
      border-radius: rem(4px) !important
      color: #3E3F42

  &__toggles
    background-color: #EEEFF3
    border: rem(1px) solid transparent
    border-radius: rem(4px)
    outline: none
    width: inherit // Allows BaseToggle to be full-width on mobile

    &:focus
      border-color: $branding

    &--disabled
      cursor: default
      opacity: 0.6

      &:focus
        border-color: transparent

  @media #{$small-and-down}
    &__toggle
      // 2 less px on each side
      padding: rem(7px) rem(10px)
</style>
