var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseToggle" }, [
    _vm.label
      ? _c(
          "label",
          {
            staticClass: "BaseToggle__label",
            attrs: { "data-cy": "base-toggle-label" }
          },
          [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
        )
      : _vm._e(),
    _c(
      "div",
      {
        class: [
          "BaseToggle__toggles row row--width-auto",
          {
            "BaseToggle__toggles--disabled": _vm.disabled
          }
        ],
        attrs: {
          "aria-disabled": "disabled",
          "data-cy": "base-toggle-toggle-wrapper",
          tabindex: _vm.disabled ? "-1" : "0"
        },
        on: {
          keypress: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            return _vm.setNextToggleActiveAndEmit($event)
          }
        }
      },
      _vm._l(_vm.dataToggles, function(toggle, index) {
        return _c("span", {
          key: index,
          class: [
            "BaseToggle__toggle",
            {
              "BaseToggle__toggle--active": toggle.active
            }
          ],
          attrs: { "data-cy": "base-toggle-option" },
          domProps: { innerHTML: _vm._s(toggle.toggle) },
          on: {
            click: function($event) {
              return _vm.setActiveAndEmit(index)
            }
          }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }